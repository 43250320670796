<template>
  <div>
    <material-card
      color="general"
      :title="$t('Product.title')"
      :text="$t('Product.subtitle')"
    >
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="zwonogreen"
            dark
            class="mb-2 z-btn-top-right"
            v-on="on"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t("Common.new_item") }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-toolbox-outline
            </v-icon>
            {{ $t("Product.subtitle") }}
            <v-spacer />
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form 
                ref="editProductForm"
                v-model="editedItem.isValid"
              >
                <v-layout wrap>
                  <v-flex>
                    <v-text-field
                      v-model="editedItem.product_name"
                      :label="$t('Product.product_name')"
                      :rules="[rules.required]"
                    />
                    <v-text-field
                      v-model="editedItem.price"
                      type="number"
                      :label="$t('Product.price')"
                      :rules="[rules.required]"
                    />
                    <v-checkbox
                      v-model="editedItem.is_special_offer"
                      :label="$t('Product.is_special_offer')"
                    />
                    <v-text-field
                      v-if="editedItem.is_special_offer"
                      v-model="editedItem.action_price"
                      type="number"
                      :label="$t('Product.action_price')"
                    />
                    <v-textarea
                      v-model="editedItem.description"
                      :label="$t('Product.description')"
                    />
                    <v-text-field
                      v-model="editedItem.short_description"
                      :label="$t('Product.short_description')"
                    />
                    <v-text-field
                      v-model="editedItem.category"
                      :label="$t('Product.category')"
                    />
                    <v-file-input
                      v-model="vfileinputfile"
                      show-size
                      :label="$t('Common.image')"
                    />
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <v-alert
              v-if="alertTextEdit"
              dense
              type="error"
            >
              {{ alertTextEdit }}
            </v-alert>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              @click="close"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t("Common.cancel") }}
            </v-btn>
            <v-btn
              color="zwonogreen"
              class="pr-5"
              :loading="isSaving"
              :disabled="!editedItem.isValid"
              @click="save"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ $t("Common.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div>
        <v-text-field
          v-model="paginationOptions.search"
          append-icon="mdi-magnify"
          :label="$t('Common.search')"
          single-line
          hide-details
          solo
          class="z-search mb-4"
        />
        <v-data-table
          :loading-text="$t('Common.loading-text')"
          :headers="headers"
          :items="productList ? productList : []"
          :options.sync="paginationOptions"
          :server-items-length="(productListMeta?.itemCount) ? productListMeta.itemCount : 0"
          :loading="paginationLoading"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          class="elevation-1"
        >
          <!-- change table header color(or other properties) -->
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-light text-general text--darken-3"
              v-text="header.text"
            />
          </template>
          <template #[`item.is_special_offer`]="{ item }">
            <!-- <v-chip :color="getColor(item.calories)" dark>{{ item.calories }}</v-chip> -->
            <v-icon
              color="general"
              size="32"
            >
              {{ getBooleanIcon(item.is_special_offer) }}
            </v-icon>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="!item.is_deleted"
                  medium
                  class="z-green-color mr-2"
                  v-bind="attrs"
                  @click="editItem(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.edit") }}</span>
            </v-tooltip>
            <v-tooltip
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  :class="!item.is_deleted ? 'z-hover-red-color' : ''"
                  :color="!item.is_deleted ? '' : 'red'"
                  v-bind="attrs"
                  @click="!item.is_deleted ? showDeleteModal(item) : null"
                  v-on="on"
                >
                  {{ !item.is_deleted ? 'delete' : 'deleted' }}
                </v-icon>
              </template>
              <span>{{ !item.is_deleted ? $t("Common.tooltips.delete") : $t("Common.tooltips.deleted") }}</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </material-card>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDelete"
      :is-visible="isDeleteModalVisible"
      @is-visible="(value) => (isDeleteModalVisible = value)"
      @confirm-btn-click="() => deleteItem()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultPopup from "../../components/DefaultPopup.vue";

export default {
  components: {
    DefaultPopup,
  },
  data: () => ({
    dialog: false,
    isDeleteModalVisible: false,
    deleteModalItemId: 0,
    editedIndex: -1,
    editedItem: {
      isValid: true,
      product_name: "",
      price: 0,
      is_special_offer: false,
      action_price: 0,
      description: "",
      image: null,
      imageInput: null,
      short_description: "",
      category: "",
    },
    defaultItem: {
      isValid: true,
      product_name: "",
      price: 0,
      is_special_offer: false,
      action_price: 0,
      description: "",
      image: null,
      imageInput: null,
      short_description: "",
      category: "",
    },
    vfileinputfile: null,
    alertTextEdit: "",
    alertTextDelete: "",
    isSaving: false,
    // table:
    paginationLoading: false,
    paginationOptions: {},
    oldSearchValue: '',
  }),

  computed: {
    ...mapGetters("product", ["productList", "productListMeta"]),

    headers() {
      return [
        { text: this.$t("Product.product_name"), value: "product_name" },
        { text: this.$t("Product.price"), value: "price" },
        {
          text: this.$t("Product.is_special_offer"),
          value: "is_special_offer",
        },
        { text: this.$t("Product.action_price"), value: "action_price" },
        { text: this.$t("Product.category"), value: "category" },
        { text: this.$t("Common.actions"), value: "actions",  sortable: false },
      ];
    },
    rules() {
      return {
        required: (value) => !!value || this.$t("Common.validation_required"),
      };
    },
  },

  watch: {
    dialog(val) {
      this.alertTextEdit = "";
      (this.vfileinputfile = null);
      if (val) this.$nextTick(() => {this.$refs.editProductForm.validate()});
      else this.close();
    },
    paginationOptions: {
      handler(val) {
        if(val.search !== this.oldSearchValue) this.paginationOptions.page = 1
        this.oldSearchValue = val.search;
        this.initialize();
      },
      deep: true, // Watch changes deeply in the object
    },
  },

  // lifecycle hooks
  beforeDestroy() {
    this.clearProductState();
  },

  methods: {
    ...mapActions("product", [
      "getProductListForCompany",
      "getProductList",
      "createProduct",
      "updateProduct",
      "deleteProduct",
      "clearProductState",
    ]),

    async initialize() {
      this.paginationLoading = true;
      this.paginationOptions.role = sessionStorage.getItem("role");
      await this.getProductList(this.paginationOptions);
      this.paginationLoading = false;
    },

    editItem(item) {
      this.editedIndex = this.productList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    showDeleteModal(item) {
      this.alertTextDelete = "";
      this.isDeleteModalVisible = true;
      this.deleteModalItemId = item?.id;
    },

    async deleteItem() {
      if (this.deleteModalItemId) {
        const isDeleted = await this.deleteProduct(this.deleteModalItemId);
        if (isDeleted) {
          this.isDeleteModalVisible = false;
        } else {
          this.alertTextDelete = this.$t("Customer.alerts.error-delete");
        }
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    async save() {
      this.isSaving = true;
      this.alertTextEdit = "";
      if (this.vfileinputfile) {
        this.editedItem.imageInput = this.vfileinputfile;
      }

      if (this.editedIndex > -1) {
        const errorMsg = await this.updateProduct(this.editedItem);
        if (errorMsg === "image" || errorMsg === false) {
          this.alertTextEdit =
            errorMsg === "image"
              ? this.$t("Product.alerts.error-image")
              : this.$t("Product.alerts.error-edit");
        } else {
          this.close();
        }
      } else {
        const errorMsg = await this.createProduct(this.editedItem);
        if(errorMsg === "image") this.editedIndex = 0;
        if (errorMsg === "image" || errorMsg === false) {
          this.alertTextEdit =
            errorMsg === "image"
              ? this.$t("Product.alerts.error-image")
              : this.$t("Product.alerts.error-create");
        } else {
          this.close();
        }
      }
      this.isSaving = false;
    },

    getBooleanIcon(val) {
      if (val) return "check";
      else return "";
    },
  },
};
</script>
